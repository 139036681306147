var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _vm.saving
            ? _c("b-col", [_c("b-spinner"), _vm._v(" Saving... ")], 1)
            : _vm._e(),
          _vm.subprovider == null
            ? _c("b-col", [_c("b-spinner"), _vm._v(" Loading... ")], 1)
            : _vm._e()
        ],
        1
      ),
      _vm.id != "new" && _vm.subprovider != null && !_vm.saving
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        header: "Sub Provider: " + this.form.values.Description
                      }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            to: { name: "subproviders" }
                          }
                        },
                        [_vm._v("Back to Sub Providers")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-4", attrs: { header: _vm.$t("Users") } },
                    [
                      _c("user-table", {
                        attrs: {
                          endpoint:
                            "/sub-providers/" +
                            _vm.subprovider.SubProviderID +
                            "/users"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: _vm.$t("Beneficiaries") }
                    },
                    [
                      _c("beneficiary-table", {
                        attrs: {
                          endpoint:
                            "/sub-providers/" +
                            _vm.subprovider.SubProviderID +
                            "/beneficiaries"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: _vm.$t("Accounting Rules") }
                    },
                    [
                      _vm.transactionMatches != null
                        ? _c("transaction-match-timeline", {
                            attrs: { matches: _vm.transactionMatches }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.id == "new" && !_vm.saving
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: "New Sub Provider" }
                    },
                    [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _c("form-field", {
                            attrs: {
                              field: _vm.form.fields.Description,
                              form: _vm.form,
                              name: "Description"
                            },
                            on: { change: _vm.onDescriptionChange }
                          }),
                          _c("p", [
                            _vm._v("Reference: " + _vm._s(_vm.reference))
                          ]),
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v(_vm._s(_vm.$t("Accounting Rules")))
                          ]),
                          _c(
                            "b-table-simple",
                            {
                              staticClass: "mt-3",
                              attrs: { striped: "", small: "" }
                            },
                            [
                              _c(
                                "b-thead",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th", [_vm._v("Transaction Type")]),
                                      _c("b-th", [_vm._v("Percentage")]),
                                      _c("b-th")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-tbody",
                                _vm._l(_vm.transactionTypes, function(
                                  type,
                                  index
                                ) {
                                  return _c(
                                    "b-tr",
                                    { key: type.JsonValue },
                                    [
                                      _c("b-td", [_vm._v(_vm._s(type.Value))]),
                                      _c(
                                        "b-td",
                                        [
                                          _c("CInput", {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "max-width": "125px",
                                              "text-align": "right"
                                            },
                                            attrs: {
                                              disabled: type.Default,
                                              type: "number",
                                              value: type.Default
                                                ? _vm.transactionTypes[0]
                                                    .Percentage
                                                : type.Percentage,
                                              min: "0",
                                              max: "100",
                                              "no-wheel": "",
                                              placeholder: 0
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.updateTransactionType(
                                                  $event,
                                                  index,
                                                  type
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        [
                                          type.JsonValue !== -1
                                            ? _c(
                                                "b-form-checkbox",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        _vm.transactionTypes,
                                                        index,
                                                        type
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: type.Default,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        type,
                                                        "Default",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "type.Default"
                                                  }
                                                },
                                                [_vm._v(" Use default ")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c("h5", { staticClass: "mt-3" }, [
                            _vm._v("Statement")
                          ]),
                          _c(
                            "b-button-group",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: {
                                    variant: _vm.createStatementSpec
                                      ? "secondary"
                                      : "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.createStatementSpec = false
                                    }
                                  }
                                },
                                [_vm._v("Select existing Statement")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: {
                                    variant: !_vm.createStatementSpec
                                      ? "secondary"
                                      : "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.createStatementSpec = true
                                    }
                                  }
                                },
                                [_vm._v("Create new Statement")]
                              )
                            ],
                            1
                          ),
                          !_vm.createStatementSpec
                            ? _c("ApiLookup", {
                                attrs: {
                                  source: "statement-specs",
                                  multiple: false,
                                  searchOnMount: true,
                                  usePost: false,
                                  keyname: "StatementReference",
                                  label: "StatementReference",
                                  clearOnSelect: true
                                },
                                model: {
                                  value: _vm.statementSpec,
                                  callback: function($$v) {
                                    _vm.statementSpec = $$v
                                  },
                                  expression: "statementSpec"
                                }
                              })
                            : _vm._e(),
                          _vm.createStatementSpec
                            ? _c(
                                "div",
                                [
                                  _vm._v(" Reference: "),
                                  _c("b-form-input", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      placeholder: _vm.reference,
                                      formatter: _vm.referenceFormatter
                                    },
                                    model: {
                                      value: _vm.newStatementSpec.Reference,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.newStatementSpec,
                                          "Reference",
                                          $$v
                                        )
                                      },
                                      expression: "newStatementSpec.Reference"
                                    }
                                  }),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v("Beneficiary")
                                  ]),
                                  _c(
                                    "b-button-group",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-nowrap",
                                          attrs: {
                                            variant: _vm.createBeneficiary
                                              ? "secondary"
                                              : "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.createBeneficiary = false
                                            }
                                          }
                                        },
                                        [_vm._v("Select existing Beneficiary")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-nowrap",
                                          attrs: {
                                            variant: !_vm.createBeneficiary
                                              ? "secondary"
                                              : "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.createBeneficiary = true
                                            }
                                          }
                                        },
                                        [_vm._v("Create new Beneficiary")]
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.createBeneficiary
                                    ? _c("ApiLookup", {
                                        attrs: {
                                          source: "portal-beneficiaries",
                                          multiple: false,
                                          searchOnMount: true,
                                          usePost: false,
                                          keyname: "BeneficiaryCode",
                                          label: "FullName"
                                        },
                                        model: {
                                          value: _vm.beneficiary,
                                          callback: function($$v) {
                                            _vm.beneficiary = $$v
                                          },
                                          expression: "beneficiary"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.createBeneficiary
                                    ? _c(
                                        "div",
                                        [
                                          false
                                            ? _c("p", { staticClass: "mb-0" }, [
                                                _vm._v("Beneficiary Code:")
                                              ])
                                            : _vm._e(),
                                          false
                                            ? _c("CInput", {
                                                attrs: {
                                                  placeholder:
                                                    "Generate automatically"
                                                },
                                                model: {
                                                  value:
                                                    _vm.newBeneficiary.Code,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.newBeneficiary,
                                                      "Code",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newBeneficiary.Code"
                                                }
                                              })
                                            : _vm._e(),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v("Beneficiary full name:")
                                          ]),
                                          _c("CInput", {
                                            attrs: {
                                              placeholder:
                                                _vm.form.values.Description
                                            },
                                            model: {
                                              value:
                                                _vm.newBeneficiary.FullName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.newBeneficiary,
                                                  "FullName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newBeneficiary.FullName"
                                            }
                                          }),
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v("Beneficiary e-mail:")
                                          ]),
                                          _c("CInput", {
                                            attrs: { type: "email" },
                                            model: {
                                              value: _vm.newBeneficiary.EMail,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.newBeneficiary,
                                                  "EMail",
                                                  $$v
                                                )
                                              },
                                              expression: "newBeneficiary.EMail"
                                            }
                                          }),
                                          false
                                            ? _c("b-form-checkbox", [
                                                _vm._v(
                                                  " Create user account with standard settings for this SubProvider "
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-alert",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                show: _vm.formErrors.length > 0,
                                variant: "danger"
                              }
                            },
                            [
                              _c(
                                "ul",
                                _vm._l(_vm.formErrors, function(error, index) {
                                  return _c("li", { key: index }, [
                                    _vm._v(" " + _vm._s(error) + " ")
                                  ])
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.saveNew()
                                    }
                                  }
                                },
                                [_vm._v("Save")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    to: { name: "subproviders" },
                                    variant: "secondary"
                                  }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$root.$data.me.Username == "EB_DD"
        ? _c("pre", [
            _vm._v("Create JSON: " + _vm._s(_vm.createJson) + "\n    ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }