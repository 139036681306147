<template>
  <b-container fluid>
    <b-row>
        <b-col v-if="saving">
            <b-spinner /> Saving...
        </b-col>

        <b-col v-if="subprovider == null">
            <b-spinner /> Loading...
        </b-col>
    </b-row>

    <b-row v-if="id != 'new' && (subprovider != null) && !saving">
      <b-col>
        <b-card :header="'Sub Provider: ' + this.form.values.Description" class="mt-4">
          <b-button variant="primary" :to="{ name: 'subproviders' }">Back to Sub Providers</b-button>
        </b-card>

        <b-card :header="$t('Users')" class="mt-4">
          <user-table :endpoint="'/sub-providers/'+ subprovider.SubProviderID +'/users'" />
        </b-card>

        <b-card :header="$t('Beneficiaries')" class="mt-4">
          <beneficiary-table :endpoint="'/sub-providers/'+ subprovider.SubProviderID +'/beneficiaries'" />
        </b-card>

        <b-card :header="$t('Accounting Rules')" class="mt-4">
          <transaction-match-timeline 
            v-if="transactionMatches != null"
            :matches="transactionMatches"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="id == 'new' && !saving">
      <b-col>
        <b-card :header="'New Sub Provider'" class="mt-4">
          <b-form @submit.prevent="onSubmit">
            <form-field 
                :field="form.fields.Description" 
                :form="form" name="Description"
                @change="onDescriptionChange" />

            <p>Reference: {{ reference }}</p>

            <h5 class="mt-3">{{ $t('Accounting Rules') }}</h5>
            <b-table-simple striped small class="mt-3">
              <b-thead>
                <b-tr>
                  <b-th>Transaction Type</b-th>
                  <b-th>Percentage</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in transactionTypes" :key="type.JsonValue">
                  <b-td>{{ type.Value }}</b-td>
                  <b-td>
                    <CInput class="m-0" 
                            :disabled="type.Default"
                            type="number"
                            :value="type.Default ? transactionTypes[0].Percentage : type.Percentage"
                            @update:value="updateTransactionType($event, index, type)"
                            min="0" 
                            max="100" 
                            no-wheel 
                            :placeholder="0" 
                            style="max-width: 125px; text-align: right;"
                    />
                    
                  </b-td>
                  <b-td>
                    <b-form-checkbox
                      v-if="type.JsonValue !== -1"
                      v-model="type.Default"
                      @change="$set(transactionTypes, index, type)"
                      
                    >
                      Use default
                    </b-form-checkbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <h5 class="mt-3">Statement</h5>
            <b-button-group class="mb-3">
              <b-button class="text-nowrap" :variant="createStatementSpec ? 'secondary': 'primary'" @click="createStatementSpec = false">Select existing Statement</b-button>
              <b-button class="text-nowrap" :variant="!createStatementSpec ? 'secondary': 'primary'" @click="createStatementSpec = true">Create new Statement</b-button>
            </b-button-group>

            <ApiLookup 
              v-if="!createStatementSpec"
              v-model="statementSpec" 
              source="statement-specs"
              :multiple="false"
              :searchOnMount="true"
              :usePost="false"
              keyname="StatementReference"
              label="StatementReference"
              :clearOnSelect="true"
            />
            
            <div v-if="createStatementSpec">
              Reference:
              <b-form-input
                class="mb-3"
                v-model="newStatementSpec.Reference"
                :placeholder="reference"
                :formatter="referenceFormatter"
              />

              <p class="mb-0">Beneficiary</p>
              <b-button-group class="mb-3">
                <b-button class="text-nowrap" :variant="createBeneficiary ? 'secondary': 'primary'" @click="createBeneficiary = false">Select existing Beneficiary</b-button>
                <b-button class="text-nowrap" :variant="!createBeneficiary ? 'secondary': 'primary'" @click="createBeneficiary = true">Create new Beneficiary</b-button>
              </b-button-group>

              <ApiLookup 
                v-if="!createBeneficiary"
                v-model="beneficiary" 
                source="portal-beneficiaries"
                :multiple="false"
                :searchOnMount="true"
                :usePost="false"
                keyname="BeneficiaryCode"
                label="FullName"
              />
              
              <div v-if="createBeneficiary">
                <p v-if="false" class="mb-0">Beneficiary Code:</p>
                <CInput
                  v-if="false"
                  v-model="newBeneficiary.Code"
                  placeholder="Generate automatically"
                />

                <p class="mb-0">Beneficiary full name:</p>
                <CInput
                  v-model="newBeneficiary.FullName"
                  :placeholder="form.values.Description"
                />

                <p class="mb-0">Beneficiary e-mail:</p>
                <CInput 
                  v-model="newBeneficiary.EMail" 
                  type="email"  
                />

                <b-form-checkbox v-if="false"
                >
                  Create user account with standard settings for this SubProvider
                </b-form-checkbox>
              </div>
            </div>

            <b-alert class="mt-3" :show="formErrors.length > 0" variant="danger">
              <ul>
                <li v-for="(error, index) in formErrors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </b-alert>

            <div class="mt-3">
              <b-button variant="primary" @click.prevent="saveNew()">Save</b-button>
              <b-button :to="{ name: 'subproviders' }" variant="secondary" class="ml-3">Cancel</b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <pre v-if="$root.$data.me.Username == 'EB_DD'">
Create JSON: {{ createJson }}
    </pre>
  </b-container>
</template>

<script>

  export default {
    name: "SubProviderDetail",
    components: {
      FormField: () => import('@/components/FormFieldV2'),
      ApiLookup: () => import('@/components/ApiLookup.vue'),
      'user-table': () => import('@/components/UserTable.vue'),
      'beneficiary-table': () => import('@/components/BeneficiaryTable.vue'),
      'transaction-match-timeline': () => import('@/components/transactions/TransactionMatchTimeline.vue'),
    },
    data() {
      return {
        providerPrefix: null,
        formErrors: [],
        reference: '',
        subprovider: null,
        createStatementSpec: false,
        newStatementSpec: { Reference: '' },
        createBeneficiary: false,
        newBeneficiary: { 
          Code: '',
          EMail: '',
          FullName: ''
        },
        statementSpec: {},
        beneficiary: {},
        transactionTypes: [],
        transactionMatches: null,
        saving: false,
        form: {
          values: null,
          validated: false,
          fields: {
            "Description": {
              label: "Description",
              required: true,
              type: "text"
            },
          }
        }  
      }
    },
    props: {
      id: String,
    },
    methods: {
      onDescriptionChange () {
        const p = this.providerPrefix
        let sp = ''
        const desc = this.form.values.Description.toUpperCase().trim()
        const words = desc.replace(/[^0-9A-Z ]/gi, '').split(' ')

        if (words.length == 1) {
          sp = words[0].substring(0, 8)
        } else {
          sp = words[0].substring(0, 4)
          sp = sp + words[words.length-1].substring(0, 4)
        }

        this.reference = p + '-' + sp
      },
      referenceFormatter (value) {
        const p = this.providerPrefix + '-'
        if (value === p) {
          return ''
        }

        value = value.toUpperCase().replace(/[^0-9A-Z-]/g, '')

        if (value.substring(0, p.length) != p) {
          value = p + value
        }
        
        return value.substring(0, p.length + 8)
      },
      updateTransactionType (value, index, type) {
        value = Number(value)
        /*
        if (value > 100) { 
          value = 100
        } else if (value < 0) {
          value = 0
        } //*/

        type.Percentage = value
        this.$set(this.transactionTypes, index, type)
      },
      async saveNew () {

        // Validate
        const ps = [] // Promise list 
        this.formErrors = []

        // Description
        if (this.form.values.Description.length < 1) {
          this.formErrors.push('Description is required')
        } else {
          ps.push(this.validate('SubProvider', this.form.values.Description))
        }

        // Transaction Types
        for (const tt of this.transactionTypes) {
          const perc = Number(tt.Percentage)
          if (perc < 0 || perc > 100) {
            this.formErrors.push('Transaction type ' + tt.Value + ' must be between 0 and 100')
          }
          console.log(tt)
        }

        if (this.createStatementSpec) {
          if (this.createBeneficiary) {
            if (!/^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.newBeneficiary.EMail)) {
              this.formErrors.push('Beneficiary e-mail is invalid')
            }
          } else {
            if (!this.beneficiary.key) {
              this.formErrors.push('Beneficiary is required')
            }
          }
        } else {
          if (!this.statementSpec.key) {
            this.formErrors.push('Statement Spec is required')
          }
        }

        if (this.formErrors.length === 0) {
          this.saving = true
        }

        try {
          const results = await Promise.all(ps)

          for (const result of results) {
            if (result !== true) {
              this.formErrors.push(result.errorMsg)
            }
          }
        } catch (e) {
          console.log('Error occurred', e)
          this.$notify({
            type: 'error',
            text: 'Something went wrong, please refresh and try again'
          }) 
          this.saving = false
          return
        }

        if (this.formErrors.length > 0) {
          this.saving = false
          return
        }

        this.$http.post('sub-providers/new', this.createJson)
          .then((response) => {
            this.id = response.data.SubProviderID
            this.$router.push({ name: 'subprovider-detail', params: { id: this.id } })
            this.load()
            this.saving = false
            this.$notify({
              type: 'success',
              text: 'Sub Provider saved'
            })
          })
          .catch((error) => {
            console.log(error)

            this.$notify({
              type: 'error',
              text: 'Something went wrong, please refresh and try again'
            })
            this.saving = false
          })
      },
      async validate (type, value) {
        const response = await this.$http.post('validate', { model: 'SubProvider', field: 'Description', value: value })
        console.log(response.data)
        return response.data
      },
      load () {
        this.subprovider = null

        if (this.id == 'new') {
          this.subprovider = {
            Description: ''
          }
          this.$set(this.form, 'values', {...this.subprovider})

          const types = [...this.$root.$data.fieldDefs.NA['Rights.TransactionTypeID'].Options].map(i => {
            i.Default = true
            i.Percentage = 0
            return i
          })
          
          types.unshift({
            Default: false,
            JsonValue: -1,
            Value: 'Default',
            Percentage: 100,
          })

          this.$set(this, 'transactionTypes', types)

          return
        }

        this.$http.get('sub-providers/'+this.id)
          .then(response => {
            this.subprovider = response.data
            this.$set(this.form, 'values', {...this.subprovider})
          })
          .catch(() => {
            this.$notify({
                type: 'error',
                text: 'Error while loading'
              })
          });
        
        this.$http.get('sub-providers/'+this.id+'/transaction-matches')
          .then(response => {
            this.transactionMatches = response.data
          })
          .catch(() => {
            this.$notify({
                type: 'error',
                text: 'Error while loading transaction matches'
              })
          });
      }
    },
    computed: {
      createJson () {
        const data = {
          Description: this.form.values.Description,
          TransactionTypes: this.transactionTypes.filter(t => { return !t.Default }).map(t => {
            return {
              Id: t.JsonValue,
              Percentage: Number(t.Percentage),
            }
          }),
        }

        if (this.createStatementSpec) {
          data['StatementSpec'] = {...this.newStatementSpec}
          if (data.StatementSpec.Reference == '') {
            data.StatementSpec.Reference = this.reference 
          }

          if (this.createBeneficiary) {
            data['StatementSpec']['Beneficiary'] = {...this.newBeneficiary}
            if (data.StatementSpec.Beneficiary.FullName == '') {
              data.StatementSpec.Beneficiary.FullName = data.Description 
            }
          } else {
            data['StatementSpec']['BeneficiaryCode'] = this.beneficiary.key
          }

        } else {
          data['StatementReference'] = this.statementSpec.key
        }

        return data
      },
    },
    watch: {

    },
    mounted() {
      this.load()
      this.providerPrefix = this.$root.$data.me.Provider.Description.toUpperCase().replace(/[^0-9A-Z]/g, '').substring(0, 6)
    }
  }
</script>

<style lang="scss" scoped>

</style>